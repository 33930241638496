import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import { ProductListing } from "../components/product-listing"
import { Seo } from "../components/seo"
import { MoreButton } from "../components/more-button"
import clsx from "clsx"
import { title } from "./index.module.css"

export default function Products({ data: { products } }) {

  console.log("1", products)
  return (
    <Layout>
      <h1 className="mx-auto text-2xl md:text-4xl text-center pt-5">YORI WOMENSWEAR</h1>
      <ProductListing products={products.products} />
      {/* {products.pageInfo.hasNextPage && (
        <MoreButton to={`/search#more`}>More products</MoreButton>
      )} */}
    </Layout>
  )
}

export const Head = () => <Seo title="All Products" />

export const query = graphql`
{
 products: shopifyCollection(handle: {eq: "yori-womenswear"}) {
   

      products {

         id
    title
 
    slug: gatsbyPath(
      filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
    )
    images {
      id
      altText
      gatsbyImageData
    }
    tags
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
  }
  }}
`
